<template>
  <!-- category breadcrumb -->
  <breadcrumb-product 
    :param="breadcrumb_param"
  />

  <div id="maincontent_container">
    <spinner v-if="loading" />

    <div v-if="!loading">
      <!-- error message -->
      <div v-if="loginError == 'Please log in to see our products details.'" class="alert alert-danger alert-dismissible fade show" role="alert">
        Please <a style="color:blue" href="/login">log in</a>  to see our products details.
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
      <div v-if="error" class="alert alert-danger alert-dismissible fade show" role="alert">
        <i class="bi bi-x-octagon-fill me-3"></i>
        {{error}}
      </div>
      <div v-if="email_error" class="alert alert-danger alert-dismissible fade show" role="alert">
        {{ email_error }}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
      <!-- message -->
      <div v-if="message" class="alert alert-success alert-dismissible fade show" role="alert">
        <i class="bi bi-check-circle-fill me-3"></i>
        {{ message }}
      </div>
      <div v-if="email_message">
      <div class="alert alert-success alert-dismissible fade show" role="alert">
        <strong>Congratulations!</strong> {{email_message}}
        <button @click="refreshPage" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
      </div>


      <!-- item information -->
      <div v-if="item" id="item" class="mt-4 mb-4">
        <div class="row">
          <!-- image slide -->
          <div class="col-md-3 mb-5">
            <!-- Carousel wrapper -->

            <!-- Modal -->
            <div class="modal fade" id="carousel_big_image" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-lg" style="display: table;">
                <div class="modal-content">
                  <div class="modal-body">

                    <div>
                      <div 
                        id="carouselExampleIndicators1"
                        class="carousel carousel-dark"
                        style="display:table; position: static;"
                        data-bs-ride="carousel"
                        data-bs-interval="false">

                          <div class="carousel-inner">
                              <div class="carousel-item" v-for="(image,index) in item.images" :class="{active:index==0}" :key="image">
                                  <img 
                                  :src="image.url"
                                  class="d-block w-100"
                                  />                            
                              </div>
                          </div>

                          <div>
                              <button
                              class="carousel-control-prev"
                              type="button"
                              data-bs-target="#carouselExampleIndicators1"
                              data-bs-slide="prev" style="margin-left:-50px;"
                              >
                              <span
                                  class="carousel-control-prev-icon"
                                  aria-hidden="true"
                              ></span>
                              <span class="visually-hidden">Previous</span>
                              </button>
                              <button
                              class="carousel-control-next"
                              type="button"
                              data-bs-target="#carouselExampleIndicators1"
                              data-bs-slide="next" style="margin-right:-50px;"
                              >
                              <span
                                  class="carousel-control-next-icon"
                                  aria-hidden="true"
                              ></span>
                              <span class="visually-hidden">Next</span>
                              </button>
                          </div>
                      </div>
                  </div>
                    
                  </div>
                  
                </div>
              </div>
            </div>
            <!-- End Modal -->

            <div
              id="carouselExampleIndicators"
              class="carousel carousel-dark slide"
              data-bs-ride="carousel"
              data-bs-interval="false">

                <div class="carousel-inner">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#carousel_big_image">
                    <div class="carousel-item" v-for="(image,index) in item.images" :class="{active:index==0}" :key="image">
                        <img 
                        :src="image.url"
                        class="d-block"
                        style="max-width: 100%; margin: 0 auto;" />                            
                    </div>
                  </a>

                    
                </div>

                <div id="carousel_small_images">
                    <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="prev"
                    >
                    <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Previous</span>
                    </button>
                    <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="next"
                    >
                    <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Next</span>
                    </button>
                </div>

              <div 
                class="carousel-indicators"
                id="small_images"
              >
              <div v-for="(image,index) in item.images" :key="image">
                  <div v-if="index == 0">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        :data-bs-slide-to="index"
                        class="active"
                        aria-current="true">
                        <img
                            class="d-block w-100 img-fluid"
                            :src="image.url"
                        />
                      </button>
                  </div>
                  <div v-else>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        :data-bs-slide-to="index">
                        <img
                            class="d-block w-100 img-fluid"
                            :src="image.url"
                        />
                      </button>
                  </div>                     
              </div>                   
              </div>
            </div>
 
            <!-- Carousel wrapper -->
          </div>
          
          <!-- end of image slide -->

          <!-- item description  -->
          <div class="col-md-7 ps-5 pe-5">
            <div>
                <img :src="item.brand_logo" >
                
            </div>
            <!-- item short title  -->
            <div class="lead fs-3 fw-bold">
              <!-- {{ item.spec_standard }} -->
              {{ item.spec_short }}
            </div>
            <!-- item long title -->
            <div v-if="item.spec_long">
              {{ item.spec_long }} 
            </div>
            <!-- item brief info -->
            <div id="brief_info" v-if="item.main_features" v-html="item.main_features" class="mt-3 line-clamp"></div>
            <a id="read_toggle_btn" type="button" class="ps-4 pt-2 pb-3 d-none" @click="handleReadBtnToggle($event)"></a>

            <div class="row">
              <!-- <div v-if="item.newitm" class="col-2">
                <span class="badge bg-danger p-2">New Item</span>
              </div> -->
              <div v-if="showInstantRebate" class="col-2">
                <span class="badge bg-success p-2">Instant Rebate</span>
              </div>
              <div v-if="showMailInRebate" class="col-2">
                <span class="badge bg-secondary p-2">Mail-In Rebate</span>
              </div>
              <div v-if="item.hotitm" class="col-2">
                <span class="badge bg-info p-2">Hot Items</span>
              </div>
            </div>
            <br />
            <div><span class="label">Package: </span>{{ item.pkg }}</div>
            <div>
              <span class="label">Ma Labs List#: </span>{{ item.lstno }}
            </div>
            <div><span class="label">Ma Labs SKU#: </span>{{ item.itmno }}</div>
            <div><span class="label">Mfr#: </span>{{ item.mfgpartno }}</div>
            <div v-if="item.barcode"><span class="label">UPC Code: </span>{{ item.barcode }}</div>

            <br>
            <div v-if="item.specorder" class="col-4">
                <span class="badge bg-warning p-2">Special-Order: Non-cancellable</span>
                <br><br>
            </div>

            <!-- display ccs logs -->
            <!-- <div v-if="item.show_ccslogos == true">

            </div> -->
            <div id="ccslogos" v-if="item.show_ccslogos == true" style="margin: 0 20px 20px"></div>

          </div>
          <!-- end of item description -->

          <!-- price/add-tocart -->
          <div class="col-md-2">
            <table id="cartTable" align="center">
              <tr>
                <td class="pb-4">
                  <div>Price:</div>
                  <div class="fs-4">{{formatPrice(item.price)}}</div>
                  <div v-if="showInstantRebate" class="text-danger">{{formatPrice(item.rebamount)}} (Instant Rebate)</div>
                  <div v-if="showInstantRebate" class="fs-2 fw-bold">{{formatPrice(item.price+item.rebamount)}}</div>
                  <div v-if="showInstantRebate">Expire: {{item.rebstop}}</div>
                </td>
              </tr>

              <tr v-if="item.balance == 0">
                <td class="pt-4 pb-4">
                  <div class="text-danger indent">
                    <i class="bi bi-chat-right-dots icon"></i> check availability
                </div>
                </td>               
              </tr>
              <tr v-if="item.balance != 0">
                <td>
                  <stock-cart :branch="branch" :is_customer="is_customer"/>
                </td>                 
              </tr>

              <tr v-if="item.balance != 0 && is_customer ">
                <td style="padding-left:10%; padding-right:10%;padding-bottom:10%">
                  <button
                  v-if="adding"
                  class="btn btn-primary btn-lg"
                  id="cart_btn"
                  @click="handleAdd"
                  disabled
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Add to Cart
                </button>
                <button
                  v-if="!adding"
                  class="btn btn-primary btn-lg"
                  id="cart_btn"
                  @click="handleAdd"
                >
                  Add to Cart
                </button>
                </td>               
              </tr>

           
             
            </table>                   
          </div>
          <!-- end of price/add-to-cart -->
        </div>

        <!--    email temp removed, may add in the future  -->
        <div style="text-align:right; position:relative; top:40px">
          <!-- Share &nbsp <img data-bs-toggle="modal" data-bs-target="#email" src="@/assets/images/share.png" width="30" height="25"/> -->
          <!-- Button trigger modal -->

          <!-- Modal -->
          <!-- <div class="modal fade" id="email" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style="margin-top:15%;">
            <div class="modal-dialog  modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="staticBackdropLabel">Share This Page With Email</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <form @submit.prevent="handleSend" style="text-align:left">
                    <div class="mb-3">
                      <label for="From" class="form-label">From</label>
                      <input v-model="from_email" type="email" class="form-control" id="InputEmail" aria-describedby="emailHelp" required>
                    </div>
                    <div class="mb-3">
                      <label for="To" class="form-label">To</label>
                      <input v-model="to_email" type="email" class="form-control" id="ToEmail" required>
                    </div>
                    <div class="mb-3">
                      <label for="Subject" class="form-label">Subject</label>
                      <input v-model="email_subject" type="text" class="form-control" id="SubjectEmail" required>
                    </div>
                    <div class="mb-3">
                      <label for="Content" class="form-label">Content</label>
                      <textarea v-model="email_body" class="form-control" id="ContentEmail" rows="10" required>
                      </textarea>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="submit" class="btn btn-primary">Send</button>
                    </div>                   
                  </form>                 
                </div>
               
              </div>
            </div>
          </div> -->
        </div>
        <!-- email end  -->

        <!-- specification -->
        <nav class="mb-5">
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <button v-if="item.has_aplus" class="active nav-link" id="nav-aplus-tab" data-bs-toggle="tab" data-bs-target="#nav-aplus" type="button" role="tab" aria-controls="nav-aplus" aria-selected="true">Enhanced Overview</button>
                <button v-if="item.spec_main" class="nav-link" :class="item.has_aplus ? '' : 'active'" id="nav-specifications-tab" data-bs-toggle="tab" data-bs-target="#nav-specifications" type="button" role="tab" aria-controls="nav-specifications" aria-selected="false">Specifications</button>
                <button v-if="item.spec_extended" class="nav-link" id="nav-Extended-tab" data-bs-toggle="tab" data-bs-target="#nav-Extended" type="button" role="tab" aria-controls="nav-Extended" aria-selected="false">Extended Specs</button>
                <button v-if="item.pdf_files && item.pdf_files.length!=0 || item.video_tour" class="nav-link" id="nav-pdf-tab" data-bs-toggle="tab" data-bs-target="#nav-pdf" type="button" role="tab" aria-controls="nav-pdf" aria-selected="false">Rich Content</button>
                <button v-if="showMailInRebate" class="nav-link" id="nav-rebate-tab" data-bs-toggle="tab" data-bs-target="#nav-rebate" type="button" role="tab" aria-controls="nav-rebate" aria-selected="false">Mail-In Rebate</button>
                <button class="nav-link" id="nav-warranty-tab" data-bs-toggle="tab" data-bs-target="#nav-warranty" type="button" role="tab" aria-controls="nav-warranty" aria-selected="false">Warranty & Return Policy</button>

                <button v-if="item.accessory_productid && item.accessory_productid.length !=0" class="nav-link" id="nav-accessory-tab" data-bs-toggle="tab" data-bs-target="#nav-accessory" type="button" role="tab" aria-controls="nav-accessory" aria-selected="false">Accessory Products</button>
                <button v-if="item.similar_productid && item.similar_productid.length !=0" class="nav-link" id="nav-similar-tab" data-bs-toggle="tab" data-bs-target="#nav-similar" type="button" role="tab" aria-controls="nav-similar" aria-selected="false">Similar Products</button>
                <button v-if="item.upsell_productid && item.upsell_productid.length !=0" class="nav-link" id="nav-upsell-tab" data-bs-toggle="tab" data-bs-target="#nav-upsell" type="button" role="tab" aria-controls="nav-upsell" aria-selected="false">Up Sell Products</button>

              </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
           <div class="tab-pane fade" :class="item.has_aplus ? 'show active' : ''" id="nav-aplus" role="tabpanel" aria-labelledby="nav-aplus-tab">
                <div class="mb-3 row">
                   <div id="spexWidget"></div> 
                </div>  
                <br>
                <div class="description mktDescription" v-html="item.prod_info.marketing"></div>
                <br>
            </div> 

            <div class="tab-pane fade" :class="item.has_aplus ? '' : 'show active'" id="nav-specifications" role="tabpanel" aria-labelledby="nav-specifications-tab">
              <div v-if="isObject(item.spec_main)">
                <div class="mb-5">
                  <div class="title col-md-8">Main Specifications</div>
                </div>   
              
                <div v-for="(value,key) in item.spec_main" :key="value" class="mktDescription">
                  <div class="mb-3 row">
                      <div class="col-md-1"><span>{{key}}</span></div>
                      <div class="col-md-6"><span v-html="value"></span></div>
                  </div>
                </div>
              </div>          
              <div v-else>
                <div class="mb-5">
                  <div class="title col-md-12">Main Features</div>
                </div> 
                <div class="mktDescription" id="spec_main" v-html="item.spec_main"></div>          
              </div>  
              <div v-if="item.prod_info">
                <div class="mb-5">
                  <div class="title col-md-12">Marketing Information</div>
                </div> 
                <div class="description mktDescription" v-html="item.prod_info"></div>
              </div>
              <div v-if="item.package_contents">
                <div class="mb-5">
                  <div class="title col-md-12">Package contents</div>
                </div> 
                <div class="description mktDescription" v-html="item.package_contents"></div>
              </div>
            </div>

            <div class="tab-pane fade" id="nav-Extended" role="tabpanel" aria-labelledby="nav-Extended-tab">
              <div v-if="isObject(item.spec_extended)">
                <div v-for="(value,key) in item.spec_extended" :key="value" id="Extended_table">
                  <div class="mb-4">
                    <div class="col-md-12"><ul class="title">{{key}}</ul> </div>
                  </div>                                     
                  <div v-for="data in value" :key="data">
                      <div v-for="(value,key) in data" :key="value" class="mktDescription" >
                          <div >{{key}}: {{value}}</div>
                      </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="mktDescription" v-html="item.spec_extended"></div>
              </div>
                <br>
            </div>

            <div class="tab-pane fade" id="nav-pdf" role="tabpanel" aria-labelledby="nav-pdf-tab" style="padding:20px">
                <div>
                    <div v-for="pdf in item.pdf_files" :key="pdf">
                        
                        <div class="mb-3 row" style="padding:5px">
                            <div class="col-md-1"><img src="@/assets/images/pdf.png" width="80" height="80"/></div>
                            <div class="col-md-10" style="padding-top:40px;"><a :href="pdf.url" target="_blank">{{ pdf.type }}</a></div>
                        </div>
                    </div>                   
                </div>
                <div v-if="item.video_tour">
                  <div class="mb-3 row" style="padding:5px">
                    <div class="col-md-1"><img src="@/assets/images/video-icon.png" width="80" height="80"/></div>
                    <div class="col-md-10" style="padding-top:40px;"><a :href="item.video_tour" target="_blank">Tour</a></div>
                  </div>
                </div>
            </div>

            <div class="tab-pane fade" id="nav-rebate" role="tabpanel" aria-labelledby="nav-rebate-tab" style="padding:20px">
              <div class="mb-3 row">
                <div class="col-md-8"><p class="lead title">Mail-In Rebate Form</p></div>
              </div>                               
              <div>
                  <div class="mb-3 row">
                      <div v-if="item.mail_in_rebate.image" class="col-md-1">
                          <a :href="'/media/'+item.mail_in_rebate.document" target="blank"><img :src="'/media/' +item.mail_in_rebate.image" :width="50" :height="50"></a>
                      </div>
                      <div class="col-md-10">
                          <div v-if="item.mail_in_rebate.document">
                            <a :href="'/media/'+item.mail_in_rebate.document" target="blank">{{item.mail_in_rebate.title}}</a>                              
                          </div>
                          <div v-else>
                            {{item.mail_in_rebate.title}}
                          </div>
                          <div>
                              <span style="color:red; font-size:12px">Period: {{item.mail_in_rebate.start_date}}  -  {{item.mail_in_rebate.end_date}}</span>
                          </div>
                      </div>
                  </div> 
              </div>                             
            </div>

            <div class="tab-pane fade" id="nav-warranty" role="tabpanel" aria-labelledby="nav-warranty-tab" style="padding:20px">
              <div class="mb-3 row">
                <div class="col-md-8"><p class="lead title">Warranty & Return Policy</p></div>
                
              </div>                               
              <div>
                  <div class="mb-3 row g-0 rma">
                      <p>Please follow Ma Labs' Limited Warranty & RMA Service Policy for all products' warranty and return qualifications.</p>
                      <div class="mb-3 row" style="padding:5px">
                            <div class="col-md-1 review_color"><a :href="'/'+RMARequest[0]" target="_blank"><img src="@/assets/images/pdf.png" width="40" height="40"/>Review</a></div>
                            <div class="col-md-2" style="padding-top:10px;"><a :href="pdf">Limited Warranty & RMA Service Policy</a></div>
                        </div>
                      <div>
                          <p>You may visit our <a href="/support/FAQ">FAQ</a> for more information or <router-link to="/contact-us/-">contact us</router-link> for further assistance.</p>
                      </div>
                  </div> 
              </div>                             
            </div>

            <!-- accessory product -->
            <div class="tab-pane fade" id="nav-accessory" role="tabpanel" aria-labelledby="nav-accessory-tab" style="padding:20px">
              <div>
                  <div v-for="prod in item.accessory_productid" :key="prod.itmno" class="row">
          
                    <!-- product image-->
                    <div class="col-md-1 div_img_thumbmail">
                      <router-link :to="'/products/details/?itmno=' + encodeURIComponent(prod.itmno)">
                      <img :src="prod.thumbnail.url" class="thumbnail_image" alt="" />
                      </router-link>  
                    </div>
                    
                    <!-- product description -->
                    <div class="col-md-8 row">
                      
                      <!-- title -->
                      <div>
                            <router-link :to="'/products/details/?itmno='+encodeURIComponent(prod.itmno)" class="hover_link"><span class="link-dark">{{ prod.spec_short }}</span></router-link>
                      </div>
                      <!-- Item#, List#, Mfr#... -->
                      <div class="small_font">
                                List #: <strong>{{ prod.lstno }}</strong> | SKU #:
                                <strong>{{ prod.itmno }}</strong> | Mfr #:
                                <strong>{{ prod.mfgpartno }}</strong> | UPC:
                                <strong>{{ item.barcode }}</strong>
                      </div>
                      
                    </div>
                    
                    <!-- stock -->
                    <div class="col-md-2">
                      <router-link :to="{name: 'ProductDetails',params: { itemno: prod.itmno }}" class="hover_link">
                      <div v-if="prod.in_stock == false" class="col">
                        <i class="bi bi-chat-right-dots fs-4"></i> <span class="link-dark">check availability</span>
                      </div>
                      <div v-else class="col">
                        <span class="link-success fw-bold">In Stock</span> 
                      </div>
                      </router-link>
                    </div>






                  </div>
                  
                </div>
            </div>
            <!-- end accessory product -->

            <!-- similar product -->
            <div class="tab-pane fade" id="nav-similar" role="tabpanel" aria-labelledby="nav-similar-tab" style="padding:20px">
                <div>
                  <div v-for="prod in item.similar_productid" :key="prod.itmno" class="row">
          
                    <!-- product image-->
                    <div class="col-md-1 div_img_thumbmail">
                      <router-link :to="'/products/details/?itmno=' + encodeURIComponent(prod.itmno)">
                      <img :src="prod.thumbnail.url" class="thumbnail_image" alt="" />
                      </router-link>  
                    </div>
                    
                    <!-- product description -->
                    <div class="col-md-8 row">
                      
                      <!-- title -->
                      <div>
                        <router-link :to="'/products/details/?itmno='+encodeURIComponent(prod.itmno)" class="hover_link"><span class="link-dark">{{ prod.spec_short }}</span></router-link>
                      </div>
                      <!-- Item#, List#, Mfr#... -->
                      <div class="small_font">
                                List #: <strong>{{ prod.lstno }}</strong> | SKU #:
                                <strong>{{ prod.itmno }}</strong> | Mfr #:
                                <strong>{{ prod.mfgpartno }}</strong> | UPC:
                                <strong>{{ item.barcode }}</strong>
                      </div>
                      
                    </div>
                    
                    <!-- stock -->
                    <div class="col-md-2">
                      <router-link :to="{name: 'ProductDetails',params: { itemno: prod.itmno }}" class="hover_link">
                      <div v-if="prod.in_stock == false" class="col">
                        <i class="bi bi-chat-right-dots fs-4"></i> <span class="check-avail">check availability</span>
                      </div>
                      <div v-else class="col">
                        <span class="link-success fw-bold">In Stock</span> 
                      </div>
                      </router-link>
                    </div>

                    <!-- product price -->
                    <div class="col-md-1">
                      <div class="row fs-5"><span>{{formatPrice(prod.price)}}</span></div>
                      
                    </div>
                    
                    <hr class="mt-4">
                  </div>
                  
                </div>
            </div>
            <!-- end similar product -->

            <!-- upsell product -->
            <div class="tab-pane fade" id="nav-upsell" role="tabpanel" aria-labelledby="nav-upsell-tab" style="padding:20px">
              <div>
                  <div v-for="prod in item.upsell_productid" :key="prod.itmno" class="row">

                    <!-- product image-->
                    <div class="col-md-1 div_img_thumbmail">
                      <router-link :to="'/products/details/?itmno=' + encodeURIComponent(prod.itmno)">
                      <img :src="prod.thumbnail.url" class="thumbnail_image" alt="" />
                      </router-link>  
                    </div>
                    
                    <!-- product description -->
                    <div class="col-md-8 row">
                      
                      <!-- title -->
                      <div>
                            <router-link :to="'/products/details/?itmno='+encodeURIComponent(prod.itmno)" class="hover_link"><span class="link-dark">{{ prod.spec_short }}</span></router-link>
                      </div>
                      <!-- Item#, List#, Mfr#... -->
                      <div class="small_font">
                                List #: <strong>{{ prod.lstno }}</strong> | SKU #:
                                <strong>{{ prod.itmno }}</strong> | Mfr #:
                                <strong>{{ prod.mfgpartno }}</strong> | UPC:
                                <strong>{{ item.barcode }}</strong>
                      </div>
                      
                    </div>
                    
                    <!-- stock -->
                    <div class="col-md-2">
                      <router-link :to="{name: 'ProductDetails',params: { itemno: prod.itmno }}" class="hover_link">
                      <div v-if="prod.in_stock == false" class="col">
                        <i class="bi bi-chat-right-dots fs-4"></i> <span class="link-dark">check availability</span>
                      </div>
                      <div v-else class="col">
                        <span class="link-success fw-bold">In Stock</span> 
                      </div>
                      </router-link>
                    </div>

                    <!-- product price -->
                    <div class="col-md-1">
                      <div class="row fs-5"><span>{{formatPrice(prod.price)}}</span></div>
                      
                    </div>
                    
                    <hr class="mt-4">
                  </div>
                  
                </div>
            </div>

            <!-- related product -->
            <div v-if="item.related_products.length > 0">
              <div>
                <div class="title col-md-12">Related Products</div>
              </div>

              <div class="d-flex align-items-center justify-content-between py-4">
                <div class="d-flex row px-4 w-100">

                    <div v-for="prod in item.related_products" :key="prod.itmno"
                      class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-5 px-2">

                    <!-- product image-->
                    <div class="text-center h-50 mb-5">
                      <router-link :to="'/products/details/?itmno=' + encodeURIComponent(prod.itmno)">
                      <img :src="prod.prime_image" class="img-fluid" alt="" />
                      </router-link>
                    </div>

                    <!-- product description -->
                      <!-- <div class="d-flex flex-column h-25"> -->
                      <div>
                        <!-- title -->
                        <div>
                          <router-link :to="'/products/details/?itmno='+encodeURIComponent(prod.itmno)" style="color: #0d6efd; text-decoration: none!important">
                            <span class="trunc-2">{{ prod.spec_short }}</span>
                          </router-link>
                        </div>
                        <!-- Item#, List#, Mfr#... -->
                        <div class="">
                          <div style="font-size: 12px">
                              SKU #: <strong>{{ prod.itmno }}</strong>
                          </div>
                        </div>
                    </div>

                    <!-- stock -->
                    <div class="">
                      <router-link :to="{name: 'ProductDetails',params: { itemno: prod.itmno }}" class="hover_link">
                      <div v-if="prod.in_stock == false" class="col">
                        <i class="bi bi-chat-right-dots" style="font-size: 13px;"></i> <span class="check">Check availability</span>
                      </div>
                      <div v-else class="col">
                        <span class="link-success fw-bold">In Stock</span> 
                      </div>
                      </router-link>
                    </div>

                    <!-- product price -->
                    <div class="col-md-1">
                      <div class="row fs-4"><span>{{formatPrice(prod.price)}}</span></div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <!-- end upsell product -->

        </div>
      </div>   
      <!-- end of item information -->

    </div>
  </div>
</template>

<script>
import Spinner from "../../components/Spinner.vue";
import StockCart from "../../components/products/StockCart.vue";
import BreadcrumbProduct from "../../components/BreadcrumbProduct.vue";
import $ from 'jquery';
import { onMounted, ref, nextTick } from "vue";
// import { useRouter } from 'vue-router'
import { useStore } from "vuex";
import { getAuthAPI, getAPI } from "../../utils/axios-api";
import { formatPrice, isInteger } from '../../utils/common';
import { useRoute, useRouter } from "vue-router";
import moment from "moment";


export default {
  name: "ProductDetails",
  components: { Spinner, StockCart, BreadcrumbProduct },
  props: ["itemno"],
  setup(props, context) {
    const store = useStore();
    const loading = ref(true);
    const adding = ref(false);
    const error = ref(null);
    const loginError = ref(null);
    const message = ref(null);
    const itmno = ref(null);
    const item = ref(null);
    let branch_inventory  = {};
    const branch = ref(store.getters.branch);
    const current = new Date();
    // const today_date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`
    const today = moment(current).format("YYYY-MM-DD")
    const showMailInRebate = ref(false)
    const showInstantRebate = ref(false)
    const RMARequest = ref([]);
    const is_customer = ref(null)
    const is_vendor = ref(null)

    is_customer.value = store.getters.isCustomer
    is_vendor.value = store.getters.isVendor 
    
    //get url parameters
    const route = useRoute();

    //itmno 
    if (props.itemno) {itmno.value = props.itemno.substring(0, 10)}
    if (typeof route.query.itmno != "undefined" && route.query.itmno != "") {
      itmno.value = route.query.itmno
    }
    
    // share email variable
    const from_email = ref("")
    const to_email = ref("")
    const email_subject = ref('')
    const email_body = ref('')
    const email_error = ref("")
    const email_message = ref("")

    //params for breadcrumb
    const breadcrumb_param = ref([])
    breadcrumb_param.value.push({"type": "itmno", "value": itmno.value})

    
    let isAuthenticated = store.getters.isAuthenticated;
    const productsDetailsAPI = ref(null);
    // const JSON5  = require('json5')

    //get item information by item no.
    var url = "/product/item/" + itmno.value;
    var url_rma = "content/pages/57";
    
    if (isAuthenticated) {
      productsDetailsAPI.value = getAuthAPI;
    } else {
      productsDetailsAPI.value = getAPI;
    }
    productsDetailsAPI.value
      .get(url)
      .then((response) => {
        loading.value = false;
        item.value = response.data.results;
        
        branch_inventory = response.data.results.balance_branches
        email_subject.value = String(item.value.spec_standard)
        let content = 'I would like to share with you this product from Ma Labs:\n\n'
                    + 'Product Description:\n'
                    + item.value.spec_standard +'\n\n'
                    + 'Product URL:\n'
                    + 'https://www.malabs.com/product/details/'+item.value.itmno + '\n'
        email_body.value = content

        
        branch.value["sj"]["stock"] = branch_inventory["San Jose"]
        branch.value["la"]["stock"] = branch_inventory["Los Angeles"]
        branch.value["mi"]["stock"] = branch_inventory["Miami"]
        branch.value["nj"]["stock"] = branch_inventory["New Jersey"]
        branch.value["il"]["stock"] = branch_inventory["Chicago"]

        // items.value.thumbnail = JSON5.parse(items.value.thumbnail).url

        //check mail in rebate is vaild or not
        if (item.value.mail_in_rebate.start_date != null){
          var mailinRebate_startDate = moment(item.value.mail_in_rebate.start_date).format("YYYY-MM-DD")
        }
        else{
          var mailinRebate_startDate = moment(today).add(1, 'days').calendar()
        }
        if(item.value.mail_in_rebate.end_date !=null){
           var mailinRebate_endDate = moment(item.value.mail_in_rebate.end_date).format("YYYY-MM-DD")
        }else{
          var mailinRebate_endDate = moment(today).add(1, 'days').calendar()
        }

        if (mailinRebate_startDate <=today && today <= mailinRebate_endDate ){
          showMailInRebate.value = true
        }
        else{
          showMailInRebate.value= false
        }


        // check instant rebate is vaild not
        if (item.value.rebstart != null){
          var instantRebate_startDate = moment(item.value.rebstart).format("YYYY-MM-DD")
        }
        else{
          var instantRebate_startDate = moment(today).add(1, 'days').calendar()
        }
        if(item.value.rebstop !=null){
           var instantRebate_endDate = moment(item.value.rebstop).format("YYYY-MM-DD")
        }else{
          var instantRebate_endDate = moment(today).add(1, 'days').calendar()
        }

        if (instantRebate_startDate <=today && today <= instantRebate_endDate && item.value.rebamount!=null && item.value.rebamount!=0){
          showInstantRebate.value = true
        }
        else{
          showInstantRebate.value= false
        }

        //show ccslogos
        if (item.value.show_ccslogos) {
          // Commented by Bo Wu on 2/23/2022
          // Get flag "show_ccslogos", if True, pass this 1. ItemNo (cpn), UPC code (upcean), MfgPartNo (pn), Brand (mf)
          // Customer ID in CNET: aaea265a
            let link = "https://ws.cs.1worldsync.com/hook?h=aaea265a" +
                       "&mf=" + item.value.brand_name +
                       "&pn=" + item.value.mfgpartno +
                       "&upcean=" + item.value.eancode +
                       "&cpn=" + item.value.itmno + 
                       "&locale=en&style=1&layout=5x1&locationId=ccslogos&authenticated=true" 
            let tag = document.createElement("script");
            tag.type = "text/javascript";
            tag.src = link
            tag.defer = "defer";
            tag.async="true";
            //document.body.appendChild(tag);  // Added by Bo Wu

        }

        //load A Plus content
        if (item.value.has_aplus == true) {
          var aplus = document.getElementById("aplus-script");
          if (aplus) {
            aplus.remove();
            let script_tag = document.createElement('script');
            script_tag.id = 'aplus-script';
            script_tag.type = 'text/javascript';
            script_tag.src = 'https://content.etilize.com/apps/spexaccess/spex-widget/spexWidget.js';
            script_tag.setAttribute('data-spex-target', 'Malabs');
            script_tag.setAttribute('data-spex-auth-key', '455488302566b635743a8ec84f8a7d12');
            script_tag.setAttribute('data-spex-profile-id', '229');
            script_tag.setAttribute('data-spex-locale', 'en_US');
            script_tag.setAttribute('data-spex-etilize-product-id', item.value.etilize_productid);
            script_tag.setAttribute('data-spex-only-enhanced-content', 'true');
            script_tag.setAttribute('data-spex-hide-powered-by', 'true');
            document.body.appendChild(script_tag);
          }
          else {
            let script_tag = document.createElement('script');
            script_tag.id = 'aplus-script';
            script_tag.type = 'text/javascript';
            script_tag.src = 'https://content.etilize.com/apps/spexaccess/spex-widget/spexWidget.js';
            script_tag.setAttribute('data-spex-target', 'Malabs');
            script_tag.setAttribute('data-spex-auth-key', '455488302566b635743a8ec84f8a7d12');
            script_tag.setAttribute('data-spex-profile-id', '229');
            script_tag.setAttribute('data-spex-locale', 'en_US');
            script_tag.setAttribute('data-spex-etilize-product-id', item.value.etilize_productid);
            script_tag.setAttribute('data-spex-only-enhanced-content', 'true');
            script_tag.setAttribute('data-spex-hide-powered-by', 'true');
            document.body.appendChild(script_tag);
          }
         
        }
        //defers the execution of a callback function until after the DOM has been updated 
        return nextTick();
      })
      .then(() => {
        //Here you can add javascript to manipulate the DOM elements.

        //Check the brief info section. If it is too long, truncate it and show the Read More button.
        const brief_info_elm = document.getElementById("brief_info");
        const readbtn = document.getElementById("read_toggle_btn");
        if (brief_info_elm.offsetHeight < brief_info_elm.scrollHeight) {
            // It is overflow and truncated. show read more / read less button
            readbtn.innerText = "Read More";
            readbtn.classList.remove('d-none');
        } 


        //
        // Initialization
        //
        var Orientation = window.outerHeight < window.outerWidth ? "landscape" : "portrait";
        var BrowserWidth = window.innerWidth;

        const breakpoints = [
          [0, 576],        // xs
          [575, 768],      // sm
          [767, 992],      // md
          [991, 1200],     // lg
          [1199, Infinity] // xl
        ];
        function findBPSize() {
          for (var i = 0; i < breakpoints.length; ++i) {
            var bp = breakpoints[i];
            var min = bp[0];
            var max = bp[1];
            if (min < BrowserWidth && BrowserWidth < max) {
              return i;
            }
          }
        }
        var BPix = findBPSize();
        const relatedArrows = document.getElementsByClassName('rel-arr');
        for (var i = 0; i < relatedArrows.length; ++i) {
          console.log(relatedArrows[i]);
        }

        // Event listeners for landscape mode
        window.matchMedia('(orientation: landscape').addEventListener('change', function() {
          if (event.matches) {
            Orientation = "landscape";
            // Do landscape stuff
          }
        });

        // Event listeners for portrait mode
        window.matchMedia('(orientation: portrait').addEventListener('change', function() {
          if (event.matches) {
            Orientation = "portrait";
            // Do portrait stuff
          }
        });

        //
        // Event listeners for window resize
        //
        window.addEventListener('resize', () => {
          BrowserWidth = window.innerWidth;
          BPix = findBPSize();

          const lineCount = getLineCount(brief_info_elm);
          if (lineCount >= 5) {
            if (readbtn.innerText == 'Read Less') return 0;

            // It is overflow and truncated. show read more / read less button
            if (readbtn.innerText == '') {
              readbtn.innerText = 'Read More';
              readbtn.classList.remove('d-none');
              brief_info_elm.classList.add('line-clamp');
            }
            if (brief_info_elm.offsetHeight < brief_info_elm.scrollHeight ) {
                // It is overflow and truncated. show read more / read less button
                readbtn.classList.remove('d-none');
            } 
            else {
              readbtn.classList.add('d-none');
              readbtn.innerText = '';
            }
          } 
          else {
            readbtn.classList.add('d-none');
            readbtn.innerText = '';
          }
        });

      })
      .catch((err) => {
        loading.value = false;
        if (err.response.status == 401) {
          loginError.value = "Please log in to see our products details.";
        } 
        else if (err.response.status == 500 || err.response.status == 404) {
          try {
            error.value = err.response.data.message
          }
          catch {
            error.value = "Product is not found";
          }
        }
        else {
          try {
            loginError.value = err.response.data.detail;
          }
          catch {
            loginError.value = err.response.statusText
          }
        }
        
      });

      //Get RMA api
      getAPI
      .get(url_rma)
      .then((response) => {
        if (response.status === 200) {
          let rma = response.data.body;         
          for (let index in rma) {
            if(rma[index].type == "document"){
              RMARequest.value.push(rma[index].value)
            }
          }
        }
      })
      .catch((err) => {
        error.value = "No file Found";
      });

    
    function getLineCount(elm) {
      // Get the computed styles for the paragraph
      const styles = window.getComputedStyle(elm);
        
        // Get the paragraph's total height (offsetHeight) and line-height
        const paragraphHeight = elm.offsetHeight;
        const lineHeight = parseFloat(styles.lineHeight);
        
        // Calculate the number of lines
        const lineCount = Math.round(paragraphHeight / lineHeight);
        return lineCount;
    }

    //stock and quantity
    function validate() {
      //clear style
      $("#stock").find("input").removeClass("alert-danger")

      //check
      for (let key in branch.value) {
        //has to be positive integer
        if (branch.value[key]["qty"] && isInteger(branch.value[key]["qty"]) == false) {
          $("#stock").find("#" + key).find("input").addClass("alert-danger")
          error.value = "Quantity is not valid."
          return false
        }
        //qty should be less than inventory
        if (branch.value[key]["qty"] > branch.value[key]["stock"]) {
          $("#stock").find("#" + key).find("input").addClass("alert-danger")
          error.value = "Quanity cannot exceed the inventory balance."
          return false
        }
      }
      
    }

    //function to add the item to cart
    const handleAdd = async () => {
      adding.value = false;
      error.value = "";
      message.value = "";

      //validate qty
      if (validate() == false) {
        adding.value = false;
        return 0
      }

      //go through each branch to add
      adding.value = true;
      var payload = {};
      let msg1 = "";
      let msg2 = "";
      for (let key in branch.value) {
        if (branch.value[key]["qty"] > 0) {
          
          payload["itmno"] = itmno.value;
          payload["branch"] = branch.value[key]["code"];
          payload["quantity"] = parseInt(branch.value[key]["qty"]);
          branch.value[key]["qty"] = "";

          try {
            //add to cart
            let result = await store.dispatch("addToCart", payload);
            msg1 = msg1 + result["message"]
            message.value = msg1;
            
            //update cart quantity page on header
            context.emit("updateCartQty");

            //log access (save this user action into log system)
            try {
              if (store.getters.isCustomer) {
                  let payload = {
                    'action':'add to cart',
                    'po_cart_id': result["data"]["po_cart_id"],
                    'po_cart_itmno': result["data"]["itmno"],
                    'po_cart_branch': result["data"]["branch"],
                    'po_cart_quantity': result["data"]["quantity"],
                  }
                  let result2 = await store.dispatch('logAccess', payload)
              }
            }
            catch {
              //do nothing when error happends
            }

          } catch (err) {
            msg2 = msg2 + err
            error.value = msg2;
          }
        }
      }

      adding.value = false;
    };
    

    // share product by sending email
    const handleSend = () => {
      let url = "/product/share-email/"
      getAuthAPI
        .post(url, {
          from_email: from_email.value,
          to_email: to_email.value,
          email_subject: email_subject.value,
          email_body: email_body.value,
        })
        .then((response) => {
          if (response.status == 200) {
            email_message.value = "Your Email has been sent successfully. Please check your mail box!"           
          }
        })
        .catch((err) => {
          email_error.value = "Email sent failed!"
        });
    }

    const refreshPage = () => {
      window.location.reload()
    }


    //function to add to cart for CNET ccslogos
    //expose the function to the global context so that CNET script can use it.
    window.addToCart = async function (id, qty) { 
      adding.value = false;
      error.value = "";
      message.value = "";

      //validate quantity
      if (isInteger(qty) == false) {
        error.value = "Quantity is not valid."
        return false
      }
      
      var payload = {}
      payload["itmno"] = id //"MSI-AP2414" //"MSI-CUB437"
      payload["branch"] = ""
      payload["quantity"] = qty
      
      try {
        //add to cart
        let result = await store.dispatch("addToCart", payload);
        message.value = result["message"]

        //log access (save this user action into log system)
        try {
          if (store.getters.isCustomer) {
              let payload = {
                'action':'add to cart',
                'po_cart_id': result["data"]["po_cart_id"],
                'po_cart_itmno': result["data"]["itmno"],
                'po_cart_branch': result["data"]["branch"],
                'po_cart_quantity': result["data"]["quantity"],
              }
              let result2 = await store.dispatch('logAccess', payload)
          }
        }
        catch {
          //do nothing when error happends
        }

        //update cart quantity page on header
        context.emit("updateCartQty")

      } catch (err) {
        error.value = err
      }
      

    }

    const isObject = (value) => {
      if (typeof value === 'string') return false;
      else if (value !== null && typeof value === 'object' && !Array.isArray(value)) return true;
      else return true;
    }

    //function to toggle Read More/Read Less button
    const handleReadBtnToggle = (event) => {
      let element = document.getElementById("brief_info");
      let intxt = event.target.innerText;
      if (intxt == "Read More") {
        element.classList.remove('line-clamp');
        event.target.innerText = "Read Less";
      }
      else {
        element.classList.add('line-clamp');
        event.target.innerText = "Read More";
      }
    }

    return { loading, 
            adding, 
            itmno, 
            item, 
            error, 
            message, 
            handleAdd, 
            branch, 
            formatPrice,
            handleSend,
            from_email,
            to_email,
            email_subject,
            email_body,
            email_error,
            email_message,
            refreshPage,
            breadcrumb_param,
            showMailInRebate,
            showInstantRebate,
            loginError,
            RMARequest,
            is_customer,
            is_vendor,
            isObject,
            handleReadBtnToggle,
      };
  }
};

</script>

<style scoped>
.container {min-height: 500px;}
#item {
  margin-top: 15px;
}

.label {
  color: #444;
}
li {
  margin: 5px 0;
}
.mktDescription {
  padding: 0px 40px 20px 40px;
  /* height: 28px; */
  color:#444;
}
.title{
  list-style: none;
  padding: 0px 40px 20px 40px;
  height: 38px;
  color:#444;
  background-color:	#F5F5F5;
  margin-top:20px;
  font-size:25px;
  font-weight: bold;

}
.
#cart_btn {
  width: 100%;
}
.nav-link{
    color:blue
}

#cartTable {
  border: #ccc 1px solid;
  border-radius: 5px;
  border-collapse: separate;
  width:100%;
  padding:20px;
}
#nav-tab button.active{
  border-bottom: 8px solid #4d88e8;
  color:#444;
  font-weight: bold;
}
#nav-tab button{
  color:grey;
}
/* #cartTable td{
  padding: 10px;

} */

.trunc-2 {
  color:  #080808 !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.trunc-2:hover {
  color:cornflowerblue !important;
  text-decoration: underline !important;
}
.check {
  font-size: 13px !important;
}
.check:hover {
  color: cornflowerblue;
}

#carousel_big_image {
  margin-top: 5%;
}
#carousel_small_images {
  position: relative; margin-top:60px;
}
#small_images {
  position:relative;margin-top:-25px;
  flex-wrap: wrap;
    justify-content: center; 
    padding: 0 50px; 
    width: 100%; 
    position: static; 
    right: 0; 
    bottom: 0; 
    margin-right: 0; 
    margin-left: 0;
}
.indent {
  text-indent: -30px;
  padding-left: 30px;
}
.icon {
  padding-left:10%;
  font-size:1.5rem;
  color:black;
}
a {
  color: grey;
  text-decoration-line: underline;
}
a:hover{
  color:cornflowerblue;
  text-decoration: underline;
}
.review_color a{
   color: #1ed2e9;
}
.rma{
   padding:0px 40px 20px 40px;
}

/deep/#spec_main li > b::after {
      content: ":";
      margin-right: 5px; /* Adds spacing between the colon and the text */
    }

.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* truncate to 5 lines */
  -webkit-line-clamp: 5;
}

.div_img_thumbmail {
  min-width:100px;
}

.hover_link:hover {
  color: #0d6efd;
  text-decoration: underline !important;
}

</style>
