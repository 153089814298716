<template>
  <div class="box mb-5">
    <h5 class="bar">Category</h5>
    <ul class="list-group ps-3 pb-2">
      <li v-for="item in subcate" class="list-group-item"><router-link :to="'/products/?catid=' + item.catid + mfidParam">{{item.categoryname}}</router-link></li>
    </ul>
  </div>

  <div class="box mt-5 mb-5">
    <h5 class="bar">Brand</h5>
    <ul class="list-group ps-3 pb-2">
      <li v-for="item in brands" class="list-group-item"><router-link :to="'/products/?page=1' + catidParam +'&mfid=' + item.mfid">{{item.brand_name}}</router-link></li>
    </ul>
  </div>

  <!-- <div v-if="filters && filters.count > 0" class="filter_section">
    <div v-for="filter in filters.results" class="mb-5">
      <div v-if="filter.attributename != 'Brand'">
        <h5>{{filter.attributename}}</h5>
        <ul class="list-group filter_list">
          <li v-for="(value, index) in filter.values" class="list-group-item" :class="(index+1>max_filter_show_number) ? 'hide':''" :name="(index+1>max_filter_show_number) ? 'hidden_filter':'showing_filter'">
            <input type="checkbox" :id="filter.atrid+'-'+value.valid" :value="value.valid" @change="pickFilter($event, filter.atrid+', '+value.valid)" :checked="isFilterChecked(filter.atrid, value.valid)">
            {{value.valueunit}} 
          </li>
        </ul>
        <div v-if="filter.values.length > max_filter_show_number" class="mt-3">
          <a  v-if="filter.values.length > max_filter_show_number" class="plus_link" :id="filter.atrid" @click.prevent="toggleShowMore(filter.atrid)"><i class="bi bi bi-plus-square-fill"></i> <span :id="'showmoreless_'+filter.atrid">Show More</span></a>
        </div>
        <button type="button" v-if="filter_submit_btn[filter.atrid]['show']" class="btn btn-sm btn-primary mt-2" @click="applySearch">Submit</button>
      </div>
    </div>
  </div> -->
</template>


<script>
import { ref, toRefs, watch } from 'vue'
import { getAPI } from '../../utils/axios-api'
import { parseFilterAtrList } from '../../utils/common';
import $ from 'jquery'
import axios from 'axios'

export default {
  props: ['catid', 'mfid', 'brands', 'urlParams', 'fParams'],
  setup(props, context) {
    const subcate = ref([])
    const brands = ref(null)
    //const urlParams = ref("")
    const catidParam = ref("")
    const mfidParam = ref("")
    const max_filter_show_number = ref(5)
    const filters = ref(null)
    var picked_filters = []  //example: [{"atrid": "A00035", "valid": ["K09267",...]}, ...]
    const org_picked_filters = {} //example: {"A00035": ["K09267", "K192734"], ...}
    const filter_submit_btn = ref({}) //example: {"A00035": {"valids": ["K09267", "K192734"], "show":false }, ...}

    try {
      if (!!props.fParams) {        
        picked_filters = parseFilterAtrList(props.fParams)

        for (var i = 0; i<picked_filters.length; i++) {
          org_picked_filters[ picked_filters[i]["atrid"] ] = []
          for (var j=0; j< picked_filters[i]["valid"].length; j++) {
            org_picked_filters[ picked_filters[i]["atrid"] ].push(picked_filters[i]["valid"][j])
          }
        }
        
        
        // var atr_list =props.fParams.split("&filter3a=")[1].split("--")
        // var atr_itm = ""
        // for (var i = 0; i < atr_list.length; i++) {
        //   atr_itm = atr_list[i].split(",")
        //   picked_filters.push({"atrid": atr_itm[0], "valid": atr_itm[1]})
          
        //   //add into orginal picked filter variable. Example: {"A00035": ["K09267", "K192734"], ...}
        //   try {
        //     org_picked_filters[atr_itm[0]].push(atr_itm[1])
        //   }
        //   catch {
        //     org_picked_filters[atr_itm[0]] = []
        //     org_picked_filters[atr_itm[0]].push(atr_itm[1])
        //   }
        //}
      }
    }
    catch(e) {
      //error
    }
    
    //get subcategory list
    var url
    if (!!props.catid) {
       url = "product/category/?catid=" + props.catid
       catidParam.value = "&catid=" + props.catid
    }
    else {
      if (!!props.mfid) {
        url = "product/category/?mfid=" + props.mfid
        mfidParam.value = "&mfid=" + props.mfid
      }
      else {
        url = "product/category-top/1/"
      }
    }
    getAPI
    .get(url)
    .then(response => {
      subcate.value = response.data["results"]
    })
    .catch(err => {
      //error.value = err
    })

    /*
    //get filter list
    var filter_url = "product/filter/?"
    if (!!props.catid) {
      filter_url = filter_url + "catid=" + props.catid + "&"
    }
    if (!!props.mfid) {
      filter_url = filter_url + "mfid=" + props.mfid + "&"
    }
    if (filter_url) {
      getAPI
      .get(filter_url)
      .then(response => {
        filters.value = response.data

        //initialize filter_submit_btn
        //example: {"A00035": {"valids": ["K09267", "K192734"], "show":false }, ...}
        for (var i = 0; i< filters.value["results"].length; i++) {
          filter_submit_btn.value[filters.value["results"][i]["atrid"]] = {
            "valids": [],
            "show": false
          }
        }
        //populate valid list in filter_submit_btn with the orignal valid list
        for (let key in org_picked_filters) {
          filter_submit_btn.value[key]["valids"] = org_picked_filters[key].slice()
        }
        

      })
      .catch(err => {
        //error.value = err
      })

    }
    */

    
    //watch for brands props that comes from parent vue
    if (!!props.mfid) {
      //if mfid is passed in, get brand list by mfid
      getAPI
      .get("/product/manufacturer/" + props.mfid)
      .then(response => {
        brands.value = response.data.results  
        
      })
      .catch(err => {
        //pass
      })
    }
    else {
      //if mfid is not passed in, get brand list from product list. So here we need to watch.
      const propsRef = toRefs(props)
      watch(propsRef.brands, function(newValue, oldValue){
        brands.value = newValue
      })
    }
    
    //remove mfid parameter and offset parameter from url
    // let result = props.urlParams.replace(/&mfid=K\d{6}/i, '')
    // result = result.replace(/&offset=\d+/i, '')
    // urlParams.value = result

    //this function will check if a filter is picked already. If is, return true and also make the item show up
    const isFilterChecked = (atrid, valid) => {
      for (var i = 0; i < picked_filters.length; i++) {
        if (picked_filters[i]["atrid"] == atrid && picked_filters[i]["valid"].indexOf(valid) != -1) {
          //make the item show up
          $("#"+atrid+"-"+valid).parent().show()
          return true
        }
      }
      return false
    }

    //this function will be triggered when check a filter. 
    //It saves the selected filters to picked_filters and then
    //show or hide submit button 
    const pickFilter = (e, ids) => {
      //tmp[0] is atrid, tmp[1] is valid
      var tmp = ids.split(", ")
      var atrid = tmp[0]
      var valid = tmp[1]

      if ($("#"+e.target.id).is(':checked')) {
        //add into filter list
        var added = false
        for (var i=0; i< picked_filters.length; i++) {
          if (picked_filters[i]["atrid"] == atrid) {
            picked_filters[i]["valid"].push(valid)
            added = true
          }
        }
        if (added == false) {
          picked_filters.push({atrid: atrid, valid: [valid]})
        }    

        //update filter_submit_btn, save selected filter into this variable
        filter_submit_btn.value[atrid]["valids"].push(valid)
      }
      else {
        //remove from filter list
        var j
        for (var i=0; i<picked_filters.length; i++) {
          if (picked_filters[i]["atrid"] == atrid){
            //if value is find, remove it
            j = picked_filters[i]["valid"].indexOf(valid)
            if (j != -1) picked_filters[i]["valid"].splice(j, 1)

            //after removal, if valid list is empty, remove the entire attribute 
            if (picked_filters[i]["valid"].length == 0) picked_filters.splice(i, 1)
            break
          }
        }

        //update filter_submit_btn, remove filter from this variable
        for (var i=0; i<filter_submit_btn.value[atrid]["valids"].length; i++) {
          if (filter_submit_btn.value[atrid]["valids"][i] == valid){
            filter_submit_btn.value[atrid]["valids"].splice(i, 1)
            break
          }
        }
   
      }

      //decide wether to show or hide submit button
      if (org_picked_filters.hasOwnProperty(atrid)) {
         if (filter_submit_btn.value[atrid]["valids"].length == org_picked_filters[atrid].length) { 
          filter_submit_btn.value[atrid]["show"] = false     
          for (var i=0; i<filter_submit_btn.value[atrid]["valids"].length; i++) {
            if(org_picked_filters[atrid].indexOf(filter_submit_btn.value[atrid]["valids"][i]) == -1){
              filter_submit_btn.value[atrid]["show"] = true
              break
            }  
          }
        }
        else {
          filter_submit_btn.value[atrid]["show"] = true
        }
      }
      else {
        if (filter_submit_btn.value[atrid]["valids"].length == 0) {
          filter_submit_btn.value[atrid]["show"] = false
        }
        else {
          filter_submit_btn.value[atrid]["show"] = true
        }
      }

            
    }

    const applySearch = () => {
      var arg = {catid:"", mfid:"", atrvals: []}
      arg["catid"] = (!!props.catid)? props.catid : ""
      arg["mfid"] = (!!props.mfid)? props.mfid : ""
      arg["atrvals"] = picked_filters
      
      //pass filter list to parent function to process the request
      context.emit('searchFilter', arg)
    }
   
    //this function is triggered when click "Show More" or "Show Less". 
    //It will show more filter options or hide them.
    const toggleShowMore = (id) => {
      //var elm = $("#"+e.target.id)
      var elm = $("#showmoreless_"+id)
      if ( elm.html() == "Show More") {
        elm.html("Show Less")
        elm.parent().find("i").attr("class", "bi bi-dash-square-fill")
        elm.parent().parent().parent().find('li[name="hidden_filter"]').each(function() {
          $(this).show()
        })
      }
      else {
        elm.html("Show More")
        elm.parent().find("i").attr("class", "bi bi bi-plus-square-fill")
        elm.parent().parent().parent().find('li[name="hidden_filter"]').each(function() {
          $(this).hide()
        })
      }

      
    }

    return {
      subcate,
      brands,
      catidParam,
      mfidParam,
      //filters,
      filter_submit_btn,
      max_filter_show_number,
      pickFilter,
      toggleShowMore,
      isFilterChecked,
      applySearch
    }
  }
}
</script>

<style scoped>
a {
  color: black;
  text-decoration: none;
}
li {
  border:0px solid #fff !important;
  line-height: 15px;
  padding-left:0 ;
}
.bar {
  padding:10px 15px;
  background-color:#eee;
}
.box {
  border: 1px solid #eee;
}
.filter_list {list-style: none;}
.hide {display:none;}
.show {display:inline-block;}
.plus_link {cursor:pointer; }
.plus_link:hover {color:rgb(76, 137, 250);}
.filter_section li  {
 text-indent: -18px;
 padding-left: 26px;
 line-height: 20px;
 padding-top: 10px;
 padding-bottom:0;
}
</style>